import Vue from 'vue'
import Router from 'vue-router'
import index from './components/index.vue'
import Home from './views/home.vue'
import WhyUs from './views/why-us.vue'
import Features from './views/features.vue'
import FAQ from './views/faq.vue'
import TNCS from './views/tncs.vue'
import Privacy from './views/privacy.vue'
import ContactUs from './views/contact-us.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    
    routes: [
        {
            path: '/', component: index, children: [
                { path: '', name: 'home-page', component: Home },
                { path: 'why-us', name: 'why-us', component: WhyUs },
                { path: 'features', name: 'features', component: Features },
                { path: 'faq', name: 'FAQ', component: FAQ },
                { path: 'tncs', name: 'tncs', component: TNCS },
                { path: 'privacy', name: 'privacy', component: Privacy },
                { path: 'contact-us', name: 'ContactUs', component: ContactUs },
            ]
        }
      
    ]
})
