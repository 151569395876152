<template>
    <div>
        <section class="inner-hero-banner" style="background-image: url('assets/images/contact-hero.jpg');">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="slider-text">
                            <span>We make your dream a reality!</span>
                            <h1>Any Question?</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
        <section class="contact-us">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 d-flex align-items-stretch">
                        <div class="info-wrap bg-primary w-100 p-lg-5 p-4">
                            <h3 class="mb-4 ">Contact Info</h3>
                            <div class="dbox w-100 d-flex align-items-start">
                                <div class="icon d-flex align-items-center justify-content-center">
                                    <span class="bi bi-geo-alt-fill"></span>
                                </div>
                                <div class="text pl-3">
                                    <p><span>Address:</span> West Park Westlands| Nairobi | Kenya</p>
                                </div>
                            </div>
                            <div class="dbox w-100 d-flex align-items-center">
                                <div class="icon d-flex align-items-center justify-content-center">
                                    <span class="bi bi-telephone-fill"></span>
                                </div>
                                <div class="text pl-3">
                                    <p><span>Phone:</span> <a href="tel:+254769090909">+254 769 090 909</a></p>
                                </div>
                            </div>
                            <div class="dbox w-100 d-flex align-items-center">
                                <div class="icon d-flex align-items-center justify-content-center">
                                    <span class="bi bi-send-fill"></span>
                                </div>
                                <div class="text pl-3">
                                    <p><span>Email:</span> <a href="mailto:info@saloplus.come">info@saloplus.com</a></p>
                                </div>
                            </div>
                            <div class="dbox w-100 d-flex align-items-center">
                                <div class="icon d-flex align-items-center justify-content-center">
                                    <span class="bi bi-globe"></span>
                                </div>
                                <div class="text pl-3">
                                    <p><span>Website</span> <a href="#">www.saloplus.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-7 d-flex align-items-stretch">
                        <div class="contact-wrap w-100 p-md-5 p-4">
                            <h3 class="mb-4">Get in touch</h3>
                            <div id="form-message-warning" class="mb-4"></div>
    
                            <div id="form-message-success" class="mb-4 text-success"> {{ successMessage }}</div>
                               <form @submit.prevent="sendEmail">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" name="name" id="name" placeholder="Name" v-model="name">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" name="email" id="email" placeholder="Email" v-model="email">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" v-model="subject">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="7" placeholder="Message" v-model="message"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <button type="submit"  class="btn btn-primary text-white" id="sendMessage">Send Message</button>
                                            <div class="submitting"></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import store from "../store";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import $ from 'jquery'
export default {
     data: function () {
        return {
            email: '',
            subject: "",
            message: '',
            name: "",
            successMessage:""
        }
    },
    methods: {
        sendEmail() {
            let vm = this
             $('#sendMessage').html('Please Wait...');
            var email_body_message = "<h2>Name:"+vm.name+"</h2><h2>Email:"+vm.email+"</h2><p>Message<br/>"+vm.message+"</p>"
            $.post({
                url: store.state.rootUrl + 'cb/api/v1/mail',
                post: "POST",
                data: JSON.stringify({
                    email_to: "josephat@liden.co.ke,management@liden.co.ke",
                    email_subject: vm.subject,
                    email_body: email_body_message,
                }),
                headers: {
                    "X-Hash-Key": "9ca9d7480aed6a78aef960101f015c78",
                    "X-App-Key": "4K7DN9O3OxZHWsapmnCkZTMvwAttZITx",
                    "Content-Type": "application/json"
                },
                cache: false,
                contentType: false,
                processData: false,
                success: function (response) {
                    $('#sendMessage').html('Send Message');
                    vm.loading = false
                    if (response.data.code == 200) {
                        vm.successMessage = response.data.message
                        Swal.fire({
                            icon: 'success',
                            title: response.data.message,
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: response.data.message,
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        });
                    }
                },
                error: function (jQxhr) {
                    vm.loading = false
                     $('#sendMessage').html('Send Message');
                    var errorMessage = "";
                    if (jQxhr.responseJSON.data.message) {
                        errorMessage = ": " + jQxhr.responseJSON.data.message;
                    }
                    Swal.fire({
                        icon: 'error',
                        title: jQxhr.responseJSON.statusDescription + "" + errorMessage,
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    });
                }
            })

        }
    }
}
</script>


