<template>
<div>
    <header class="main-header">
        <div class="container">
            <div class="row">
                <!-- Navigation -->
                <navigation  />
            </div>
        </div>
    </header>
    <div id="main-content">
         <router-view id="main-content"></router-view>
    </div>
    <footer class="site-footer">
        <footerNavigation />
    </footer>
</div>
</template>

<script>
import navigation from "@/components/navigation";
import footerNavigation from "@/components/footer-navigation";
export default {
    data() {
        return {
            showNav: true
        }
    },
    components: {
        navigation, footerNavigation
    },
    mounted() {

    },
    methods: {
        showNavFn() {
            this.showNav = true;
        },

    }
}
</script>
