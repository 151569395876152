import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    rootUrl :  "https://api.prod.saloplus.com/", 
    key:"",
    username:'',
    startDate:"",
    endDate:"",
    selectedList:[]
  },
  mutations: {
    setUserKey(state,data){
      state.key=data;
    },
    setUsername(state,data){
      state.username=data;
    },
    setStartDate(state,data){
      state.startDate=data;
    },
    setEndDate(state,data){
      state.endDate=data;
    },
    setSelectedList(state,data){
      state.selectedList=data;

    }
  },
  actions: {

  }
});
