<template>
<nav class="navbar navbar-expand-lg navbar-dark">
    <router-link :to="{ name: 'home-page' }" class="navbar-brand"><img src="assets/images/saloPlus-logo.svg" /> </router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicExampleNav" aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="basicExampleNav">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" :class="{ 'active': this.$route.name == 'home-page' }">
                <router-link :to="{ name: 'home-page' }" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item" :class="{ 'active': this.$route.name == 'why-us' }">
                <router-link :to="{ name: 'why-us' }" class="nav-link">Why Us</router-link>
            </li>
            <li class="nav-item" :class="{ 'active': this.$route.name == 'features' }">
                <router-link :to="{ name: 'features' }" class="nav-link">Features</router-link>
            </li>
            <li class="nav-item" :class="{ 'active': this.$route.name == 'FAQ' }">
                <router-link :to="{ name: 'FAQ' }" class="nav-link">FAQs</router-link>
            </li>

            <li class="nav-item" :class="{ 'active': this.$route.name == 'ContactUs' }">
                <router-link :to="{ name: 'ContactUs' }" class="nav-link">Helpline</router-link>
            </li>

        </ul>
    </div>
</nav>
</template>
<script>
import $ from 'jquery'
export default {
    watch: {
        $route() {
            $("#basicExampleNav").removeClass("show");
        }
    }
}
</script>

